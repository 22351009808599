import * as React from "react"

import AtroposReact from 'atropos/react'

// import jennieUrl from'./images/jennie.png'
// import classroomUrl from'./images/classroom.jpg'
// import classroomUrlTall from'./images/classroomTall.jpg'
// import cuddlesUrl from'./images/cuddles.png'
// import penelopeUrl from'./images/penelope.png'
// import titleUrl from'./images/title.png'

import'./styles.css'

import{useWindowSize}from'hendrixhooks'

import'atropos/css'


const src=name=>`/images/${name}.png`

const Atropos=({filter,...props})=>{
  const{width,height}=useWindowSize()

  const dif=width-height


  const ims=[
    <img className="atropos-banner-spacer" src={width>height?src('classroom'):src('classroom-tall')} alt="" style={{
      height:'100vh',
      width:'100vw',
    }}/>,

    <img data-atropos-offset="-2.5" src={width>height?src('classroom'):src('classroom-tall')} alt="" style={{
      height: height>width?dif>-200?'auto':'115vh':dif>300?'auto':'115vh',
      width: width>height?dif>300?'108vw':'auto':dif>-200?'108vw':'auto',
      filter:!width&&!height?'blur(20px)':'blur(0px)',
      transition:'filter .1s'
    }}/>,

    <img data-atropos-offset="-.5" src={src('jennie')} alt="" style={{
      height:'50%',
      position:'absolute',
      left:'-20%',
      top:width>height?'40%':'30%',
      opacity:width&&height?1:0,
      transition:'opacity .1s'
    }}/>,

    <img data-atropos-offset="-.5" src={src('cuddles')} alt="" style={{
      height:'20%',
      position:'absolute',
      top:'85%',
      left:'-40%',
      opacity:width&&height?1:0,
      transition:'opacity .1s'
    }}/>,

    <img data-atropos-offset="-.5" src={src('title')} alt="" style={{
      height:'30%',
      position:'absolute',
      left:'1%',
      top:'10%',
      opacity:width&&height?1:0,
      transition:'opacity .1s'
    }}/>,

    <img data-atropos-offset="1" src={src('penelope')} alt="" style={{
      height: '60%',
      position: 'absolute',
      top: '50%',
      left: '3%',
      opacity:width&&height?1:0,
      transition:'opacity .1s'
    }}/>,
  ]


  const _ims=filter?.length?filter.map(o=>ims[o]):ims


  return <AtroposReact
    className="atropos-banner"
    highlight={true}
    rotateYMax={5}
    rotateXMax={1}
    activeOffset={100}
    alwaysActive={true}
    shadow={false}
    children={_ims.map((o,i)=><React.Fragment key={i}>{o}</React.Fragment>)}
  />
};


export default Atropos
